/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    object, shape, arrayOf, string, number, array,
    bool,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DesktopMobile from '../../../../../../../helpers/DesktopMobile/DesktopMobile';
import DesktopTabletImoc from './Partials/DesktopTabletImoc/DesktopTabletImoc';
import MobileImoc from './Partials/MobileImoc/MobileImoc';
import { trackEvent } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getPageViewFiredState } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';

/*
    If viewport is add in any one of IMOC, only that IMOC will be displayed on the selected viewport
    If no viewport is selected on any IMOC, last imoc will be displayed on both desktop and mobile (backward compatibility)
*/

const GraphqlImoc = ({
    imocBlock, brand, samedayCutOff, isSameDayCollectionV2,
}) => {
    const imoc_group = imocBlock?.imoc_group.length > 0 ? imocBlock?.imoc_group : [imocBlock?.imoc_group] || [];
    // initially assign value to both mobile and desktop (backward compatibility)
    let mobileBlock = imoc_group;
    let desktopBlock = imoc_group;

    // check if viewport is added to any one of IMOC block. Assign that imoc block or assign null if no viewport is selected
    if (imoc_group?.some((block) => block?.viewport?.length && imoc_group?.some((group) => group?.viewport?.[0]?.select))) {
        mobileBlock = imoc_group.filter((block) => block.viewport[0]?.select?.includes('Mobile')) || null;
        desktopBlock = imoc_group.filter((block) => block.viewport[0]?.select?.includes('Desktop')) || null;
    }
    const dispatch = useDispatch();
    const { whichSameDayCollection } = useFlags();
    const pageView = useSelector(getPageViewFiredState);

    useEffect(() => {
        if (samedayCutOff) {
            if (!isSameDayCollectionV2 && typeof whichSameDayCollection === 'string') {
                dispatch(trackEvent({
                    eventCategory: 'Experiment',
                    eventAction: 'Sameday collection',
                    eventLabel: 'Control',
                }));
            }
            // else if (typeof whichSameDayCollection === 'string') {
            //     dispatch(trackEvent({
            //         eventCategory: 'Experiment',
            //         eventAction: 'Sameday collection',
            //         eventLabel: 'Control',
            //     }));
            // } // adding this to child component
        }
    }, [whichSameDayCollection, pageView]);

    return (
        <>
            <DesktopMobile
                mobile={() => <MobileImoc imocBlock={mobileBlock} samedayCutOff={samedayCutOff} isSameDayCollectionV2={isSameDayCollectionV2} />}
                desktop={() => <DesktopTabletImoc imocBlock={desktopBlock} brand={brand} samedayCutOff={samedayCutOff} isSameDayCollectionV2={isSameDayCollectionV2} />}
                breakpoint={600}
                ignoreHover
            />
        </>
    );
};

GraphqlImoc.propTypes = {
    imocBlock: shape({
        imoc_group: arrayOf(
            shape({
                layout: string,
                header: string.isRequired,
                copy: string.isRequired,
                read_more_copy: string,
                image: object,
                offer_section: object,
                font_color: string,
                font_size: number,
            }),
        ).isRequired,
    }).isRequired,
    brand: string.isRequired,
    samedayCutOff: shape({
        entries: arrayOf(shape({
            cutoff_entries: array,
        })),
    }),
    isSameDayCollectionV2: bool,
};

GraphqlImoc.defaultProps = {
    samedayCutOff: {},
    isSameDayCollectionV2: false,
};

export default GraphqlImoc;
