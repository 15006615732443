/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    shape, arrayOf, string, bool, array, number,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import DeliveryTable from '../../../DeliveryTable/DeliveryTable';
import CountDownTimerBanner from '../CountDownTimerBanner/CountDownTimerBanner';
import { transformCharacters } from '../../../../../../../../../helpers/markdown/characterTransformer';

const useStyles = makeStyles((theme) => ({
    mobileImocContainer: ({ imocBackgroundColor }) => ({
        margin: '0 -10px 15px -10px', // negative to stretch full-width because of site wrapper margin
        boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
        padding: '15px',
        backgroundColor: imocBackgroundColor || '#fff',
    }),
    innerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    headerWrapper: ({ headerColor }) => ({
        '& h1': {
            margin: 0,
            fontSize: theme.typography?.fontSize || '16px',
            lineHeight: '18px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            flex: '1 1 80%',
            color: headerColor || theme.palette.colorPrimary,
        },
    }),
    readMoreButton: {
        outline: 'transparent',
        fontSize: theme.typography?.fontSize || '16px',
        padding: 0,
        margin: '0 0 0 10px',
        flex: '1 1 20%',
        textAlign: 'right',
        border: 'none',
        background: 'transparent',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        whiteSpace: 'nowrap',
    },
    imocReadMoreCopyContainer: ({ fontSize }) => ({
        paddingTop: '10px',
        fontSize: fontSize || '15px',
        '& h2, h3, h4, h5, h6': {
            fontSize: theme.palette?.text?.fontSize15 || '15px',
            margin: 0,
            padding: 0,
            textAlign: 'justify',
        },
    }),
    imgLogo: {
        width: '100%',
        [theme.breakpoints.down('600')]: {
            maxWidth: '250px',
        },
    },
    imocReadMoreCopyWrapper: ({ imocCopyColor }) => ({
        color: imocCopyColor || theme.palette.common.black,
        height: 'auto',
        width: '100%',
        overflow: 'hidden',
    }),
    imocLessCopyWrapper: {
        overflow: 'hidden',
        height: '0',
    },
    subtextHeader: {
        '& h2, & h3, & h4, & h5, & h6, & p': {
            margin: 0,
            fontSize: theme.palette?.text?.fontSize12 || '12px',
        },
    },
    showSameDay: {
        paddingBottom: '20px',
    },
}));

const MobileImoc = ({ imocBlock, samedayCutOff, isSameDayCollectionV2 }) => {
    if (!imocBlock) {
        return null;
    }
    let header;
    let headerColor = '';
    let subtextHeader;
    let exposeCopy = false;
    let imocReadMoreCopy;
    let imocCopy;
    let imocCopyColor = '';
    let imocShowSameDay;
    let imageImoc;
    let imocBackgroundColor = '';
    let languageAttribute = '';

    // font size and color are on IMOCs from 'Standard Collections', applied to read more copy
    let fontSize;
    // let font_color;

    imocBlock.forEach((block) => {
        // To Do: temp fix(block.name) to render imocs for (cco/18b) will all brands use web imoc like flowers?
        if (block?.name?.toLowerCase() === 'web imoc' || block?.name) {
            header = block?.header;
            headerColor = block?.header_color_mobile?.color;
            subtextHeader = block?.sub_header_text || null;
            exposeCopy = block?.expose_copy;
            imocCopy = block?.copy;
            imocCopyColor = block?.copy_color_mobile?.color;
            imocReadMoreCopy = block?.read_more_copy;
            imocShowSameDay =  block?.show_same_day_cutoff_times;
            imageImoc = block?.image;
            fontSize = block?.font_size;
            imocBackgroundColor = block?.background_color_mobile?.color || block?.background_color;
            languageAttribute = (block?.language_attribute && block.language_attribute !== 'en') ? block.language_attribute : '';

            // 8868; this was previously not fully implemented. font_color is an object and won't be applied being passed without 'font_color.color' being broken out. Default value from CMS is '#ebebeb', Since every Standard Collection entry will need to be updated, usage should be avoided until it is properly set on all collections.
            // font_color = block?.font_color;
        }
    });

    const [isReadMoreVisible, setIsReadMoreVisible] = useState(exposeCopy || false);
    const { whichSameDayCollectionWithConfig } = useFlags();
    const whichSameDayCollection = whichSameDayCollectionWithConfig?.type;

    const classes = useStyles({
        headerColor,
        imocCopyColor,
        fontSize,
        imocBackgroundColor,
    });

    if (!header && !imageImoc) {
        return null;
    }
    return (
        <>
            {imocShowSameDay && (!isSameDayCollectionV2 && typeof whichSameDayCollection === 'string') && <CountDownTimerBanner samedayCutOff={samedayCutOff} imocBanner />}
            <div data-testid="mobile-imoc" className={classes.mobileImocContainer}>
                <div className={classes.innerContainer}>
                    {imageImoc ? (
                        // 8868; img alt left empty
                        <img src={imageImoc?.url} className={classes.imgLogo} alt="" />
                    ) : (
                        <div className={classes.headerWrapper} lang={languageAttribute}>
                            <h1>{transformCharacters(header)}</h1>
                            {subtextHeader && (
                                <>
                                    <ReactMarkdown
                                        source={subtextHeader}
                                        skipHtml
                                        className={classes.subtextHeader}
                                    />
                                </>
                            )}
                        </div>
                    )}
                    <button type="button" aria-hidden="true" tabIndex="-1" className={classes.readMoreButton} onClick={() => setIsReadMoreVisible(!isReadMoreVisible)}>{isReadMoreVisible ? 'close' : 'read more'}</button>
                </div>
                <div className={isReadMoreVisible ? classes.imocReadMoreCopyWrapper : classes.imocLessCopyWrapper}>
                    <div
                        data-testid="imocCopyMobile"
                        className={classes.imocReadMoreCopyContainer}
                    >
                        <ReactMarkdown
                            aria-hidden="true"
                            renderers={{ paragraph: ({ children }) => (languageAttribute ? <p><span lang={languageAttribute}>{children}</span></p> : <p>{children}</p>) }}
                            source={imocCopy}
                            skipHtml
                        />
                        <ReactMarkdown aria-hidden="true" source={imocReadMoreCopy} skipHtml />
                    </div>
                    {imocShowSameDay && !isSameDayCollectionV2 && typeof whichSameDayCollection === 'string' &&  <div className={classes.showSameDay}><DeliveryTable samedayCutOff={samedayCutOff} /></div>}
                </div>
            </div>
        </>
    );
};

MobileImoc.propTypes = {
    imocBlock: arrayOf(
        shape({
            header: string.isRequired,
            expose_copy: bool.isRequired,
            copy: string.isRequired,
            read_more_copy: string,
            show_same_day_cutoff_times: bool.isRequired,
            font_color: string,
            font_size: number,
        }),
    ).isRequired,
    samedayCutOff: shape({
        entries: arrayOf(shape({
            cutoff_entries: array,
        })),
    }),
    isSameDayCollectionV2: bool,
};

MobileImoc.defaultProps = {
    samedayCutOff: {},
    isSameDayCollectionV2: false,
};

export default MobileImoc;
